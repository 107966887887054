import { IApiBaseResponse } from "types/index";
import { api } from "utils/api";
import { ClientFlag } from "utils/launchDarkly";
import { QuoteDetails } from "utils/quote";
import {
  GetBaseUrl as GetBaseUrlForEnv,
  GetBaseCustomerAppURL,
} from "utils/api";
export enum EXTERNAL_ROUTES {
  LOGIN = "/login",
  LPG_DIRECT_PAYMENT = "/billing/payments/direct",
  LPG_CUSTOMER = "/billing/payments/customer",
  LPG_CREATE_CUSTOMER = "/billing/payments/customers",
  LPG_DYNAMIC_PRICING = "/billing/quotes/variable-cost-by-fleet",
  LPG_DYNAMIC_PRICING_FIXED_FLEET = "/billing/quotes/variable-cost-by-fixed-fleet",
  UW_UNDERWRITING = "/embeddedapi/underwriting/vehicle",
  UW_VIN_DETAILS = "/embeddedapi/vindetails",
  APP_DASHBOARD = "/embeddedApi/application",
}

const GetValueForEnvironment = (
  prod: string,
  stage?: string,
  dev?: string
): string => {
  let value = dev || stage || prod;

  if (process.env.ENVIRONMENT === "production") {
    value = prod;
  } else if (process.env.ENVIRONMENT === "staging") {
    value = stage || "";
  }

  return value;
};

export const BASE_API =
  process.env.ENVIRONMENT === "production"
    ? `https://api.getaddify.com`
    : `https://api.getaddify-staging.com`;

export const BASE_LULA = "https://getaddify.com";

export const BASE_CUSTOMER_APP =
  process.env.ENVIRONMENT === "production"
    ? `https://app.getaddify.com`
    : `https://app.getaddify-staging.com`;

export const BASE_PADDOCKS =
  process.env.ENVIRONMENT === "production"
    ? `https://paddocks.getaddify.com`
    : `https://paddocks.getaddify-staging.com`;

export const BASE_APPLICATIONS_DASHBOARD =
  process.env.ENVIRONMENT === "production"
    ? `https://applicant.paddocks.getaddify.com`
    : `https://applicant.paddocks.getaddify-staging.com`;

export const BASE_RENTALS =
  process.env.ENVIRONMENT === "production"
    ? `https://apply.getaddify.com`
    : `https://apply.getaddify-staging.com`;

export const BASE_ONBOARDING =
  process.env.ENVIRONMENT === "production"
    ? `https://onboarding.getaddify.com`
    : process.env.ENVIRONMENT === "staging"
    ? `https://onboarding.getaddify-staging.com`
    : "http://localhost:3000";

export const BASE_AUTH =
  process.env.ENVIRONMENT === "production"
    ? `https://auth.getaddify.com`
    : `https://auth.getaddify-staging.com`;

export const BASE_AUTH_PUBLIC =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
    ? `https://auth.getaddify.com`
    : `https://auth.getaddify-staging.com`;

export const BASE_DOCUMENT_SERVICE =
  process.env.ENVIRONMENT === "production"
    ? `https://api.documents.getaddify.com`
    : `https://api.documents.getaddify-staging.com`;

export const BASE_ORP_QUERY_SERVICE =
  process.env.ENVIRONMENT === "production"
    ? `https://us-central1-production-storage-b567.cloudfunctions.net/get-orp-accounts`
    : "https://us-central1-staging-storage-7a3b.cloudfunctions.net/get-orp-accounts";

export const BASE_LULASAFE =
  process.env.ENVIRONMENT === "production"
    ? `https://lulasafe.getaddify.com`
    : `https://lulasafe.getaddify-staging.com`;

export const GetBaseUrl = () => {
  if (process.env.ENVIRONMENT == "production") {
    return ONBOARDING_URLS.production;
  } else if (process.env.ENVIRONMENT == "staging") {
    return ONBOARDING_URLS.staging;
  } else {
    return ONBOARDING_URLS.development;
  }
};

export const GetBaseCallbackUrl = () => {
  if (process.env.ENVIRONMENT == "production") {
    return ONBOARDING_URLS.production;
  } else {
    return ONBOARDING_URLS.staging;
  }
};

export const getExternalRoute = (
  route: EXTERNAL_ROUTES,
  comUpdates: boolean,
  isProduction: boolean
) => {
  if (isProduction) {
    return route.replace("staging-lula", "lula");
  }

  return route.replace(
    "getaddify.com",
    comUpdates ? "getaddify.com" : "getaddify.com"
  );
};

export enum ROUTES {
  INDEX = "/",
  PERSONAL_INFORMATION = "/personal-information",
  BUSINESS_DETAILS = "/business-details",
  FORMCRAFT_CCP = "/formcraft/ccp",
  FORMCRAFT_ORP = "/formcraft/orp",
  MY_FLEET = "/my-fleet",
  MY_QUOTE = "/my-quote",
  SETUP_MY_ACCOUNT = "/setup-my-account",
  UPDATE_MY_FLEET = "/update-my-fleet",
  UPDATED_QUOTE = "/updated-quote",
  RENTAL_AGREEMENT = "/rental-agreement",
  LOSS_RUNS = "/loss-runs",
  APPLICATION_PENDING = "/application-pending",
  COVERAGE_AGREEMENT = "/coverage-agreement",
  CHECKOUT = "/checkout",
  PAYMENT = "/payment",
  FLEET = "/fleet",
  QUOTE = "/quote",
  OFF_RENTAL_POLICY = "/off-rental-policy",
  CONTINUE = "/continue",
  COMPLETE = "/complete",
  INTEGRATIONS = "/integrations",
  SAMSARA_OAUTH_HANDLER = "/samsara-oauth-handler",
  LULA_APP_SAMSARA_OAUTH_HANDLER = "/samsara_oauth_handler",
  MOTIVE_OAUTH_HANDLER = "/telematics/redirect-hub",
  UNDERWRITER_REVIEW = "/underwriter-review",
}

export enum API_ROUTES {
  ACCOUNT = "/api/account",
  CONTEXT = "/api/context",
  CONTACT = "/api/contact",
  USER = "/api/user",
  INITIALIZE_USER = "/api/user/initialize",
  USER_CONTINUITY = "/api/continuity",
  CREATE_ACCOUNT = "/api/account",
  FLEET = "/api/fleet",
  VEHICLES = "/api/vehicles",
  VEHICLE = "/api/vehicle",
  UNDERWRITING_CHECK = "/v1/underwriting/vin/",
  REGISTRANT = "/api/registrant",
  PURCHASE = "/api/purchase",
  LOT = "/api/lot",
  SIGNED_AGREEMENT = "/api/signed-agreement",
  LIENHOLDER = "/api/lienholder",
  BILLING_CUSTOMER = "/api/billing/customer",
  BILLING_PAYMENTS = "/api/billing/payments",
  BILLING_SETUP = "/api/billing/setup",
  SwitchCoverage = "/api/switch-coverage",

  DOCUMENT_SERVICE = "/api/document-service",
  DOCUMENTS = "/api/documents",

  CREATE_SF_LEAD = "/api/salesforce/v2/lead",
  CREATE_SF_ACCOUNT = "/api/salesforce/v2/account",
  CREATE_SF_VEHICLES = "/api/salesforce/v2/vehicles",
  UPDATE_SF_STAGE = "/api/salesforce/v2/stage",
  GET_SF_OPPORTUNITY = "/api/salesforce/v2/opportunity/",

  DEPRECATED_VEHICLE = "/api/deprecated-vehicle",
  DEPRECATED_FLEET = "/api/deprecated-fleet",

  REMOVE_DROP_OFF_ID = "/api/notifications/selfserve/continue",

  SF_TRUCKING_LEAD = "/api/salesforce/v2/trucking/lead",
  SF_TRUCKING_ACCOUNT = "/api/salesforce/v2/trucking/account",
  SF_TRUCKING_COVERWHALE = "/api/salesforce/v2/trucking/coverwhale",
  SF_TRUCKING_VEHICLES = "/api/salesforce/v2/trucking/vehicles",
  SF_TRUCKING_DRIVERS = "/api/salesforce/v2/trucking/drivers",
  TRUCKING_SLACK_MESSAGE = "/api/notifications/trucking/slack/quote",

  APP_FLOW = "/api/appFlow",
  APP_FLOW_DOCUMENT = "/api/appFlow/document",
  QUEUE_APP_FLOW_DOCUMENT = "/api/appFlow/document/queue",
  APP_FLOW_FINAL_SYNC = "/api/appFlow/final-sync",
  QUEUE_APP_FLOW_FINAL_SYNC = "/api/appFlow/final-sync/queue",

  IMPORT_ACCOUNTS = "/api/import/accounts",
  IMPORT_ACCOUNT = "/api/import/account",
  IMPORT_ACCOUNTS_STATUS = "/api/import/accounts-status",
  IMPORT_ACCOUNTS_REMINDER = "/api/import/accounts-reminder",
  IMPORT_ACCOUNT_REMINDER = "/api/import/account-reminder",
}

export enum LULASAFE_ROUTES {
  SIGN_MSA = "/risk/.hidden/graphql",
}

export enum QUEUE_ROUTES {
  SYNC_BILLING_CUSTOMER = "/api/billing/customer/sync",
  SYNC_SALESFORCE = "/api/salesforce/v2/sync",
  SALESFORCE_DELETE_VEHICLE = "/api/salesforce/v2/vehicle/delete",
  ORP_UW_SYNC = "/api/orp/run",
  COVERAGE_AGREEMENT_POLL = "/api/signed-agreement-poll",
}

export enum CRON_JOBS {
  ORP_FLOW = "/api/orp/process-accounts",
  CONTINUE_NOTIFICATION = "/api/notifications/selfserve/continue",
}

export enum PARTNER_ROUTES {
  CARSYNC = "https://carsync.io/insurance",
}

export type FlowRoute = {
  current: ROUTES;
  prevRoute: (accountId?: string, flags?: ClientFlag[]) => Promise<string>;
  nextRoute: (accountId?: string, flags?: ClientFlag[]) => Promise<string>;
};

const standardRoute = (route: ROUTES, accountId?: string): string => {
  if (!accountId) {
    throw new Error("Account ID is required");
  }
  return `${route}/${accountId}`;
};

const STANDARD_ROUTES_EXCLUDING_QUOTE_SETUP_ACCOUNT: FlowRoute[] = [
  {
    prevRoute: async () => ROUTES.INDEX,
    current: ROUTES.INDEX,
    nextRoute: async (accountId) => standardRoute(ROUTES.PERSONAL_INFORMATION, accountId),
  },
  {
    prevRoute: async () => ROUTES.INDEX,
    current: ROUTES.PERSONAL_INFORMATION,
    nextRoute: async (accountId) => standardRoute(ROUTES.BUSINESS_DETAILS, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.PERSONAL_INFORMATION, accountId),
    current: ROUTES.BUSINESS_DETAILS,
    nextRoute: async (accountId) => standardRoute(ROUTES.MY_FLEET, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.BUSINESS_DETAILS, accountId),
    current: ROUTES.MY_FLEET,
    nextRoute: async (accountId) => standardRoute(ROUTES.MY_QUOTE, accountId),
  },
];

export const CONTINUOUS_COVERAGE_ROUTES_PROGRESS: FlowRoute[] = [
  {
    prevRoute: async () => ROUTES.INDEX,
    current: ROUTES.INDEX,
    nextRoute: async (accountId) => standardRoute(ROUTES.PERSONAL_INFORMATION, accountId),
  },
  {
    prevRoute: async () => ROUTES.INDEX,
    current: ROUTES.PERSONAL_INFORMATION,
    nextRoute: async (accountId) => standardRoute(ROUTES.BUSINESS_DETAILS, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.PERSONAL_INFORMATION, accountId),
    current: ROUTES.BUSINESS_DETAILS,
    nextRoute: async (accountId) => standardRoute(ROUTES.MY_FLEET, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.BUSINESS_DETAILS, accountId),
    current: ROUTES.MY_FLEET,
    nextRoute: async (accountId) => standardRoute(ROUTES.MY_QUOTE, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.MY_FLEET, accountId),
    current: ROUTES.MY_QUOTE,
    nextRoute: async (accountId) => standardRoute(ROUTES.FORMCRAFT_CCP, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.MY_QUOTE, accountId),
    current: ROUTES.FORMCRAFT_CCP,
    nextRoute: async (accountId) => standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.FORMCRAFT_CCP, accountId),
    current: ROUTES.UPDATE_MY_FLEET,
    nextRoute: async (accountId) => standardRoute(ROUTES.APPLICATION_PENDING, accountId),
  },
  {
    prevRoute: async (accountId, flags) => { return standardRoute(ROUTES.UPDATE_MY_FLEET, accountId); },
    current: ROUTES.APPLICATION_PENDING,
    nextRoute: async (accountId) => standardRoute(ROUTES.SETUP_MY_ACCOUNT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.MY_FLEET, accountId),
    current: ROUTES.SETUP_MY_ACCOUNT,
    nextRoute: async (accountId) => standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.SETUP_MY_ACCOUNT, accountId),
    current: ROUTES.UPDATE_MY_FLEET,
    nextRoute: async (accountId, flags) => {
      const quoteDetails = await api<any, QuoteDetails & IApiBaseResponse>(
        `${API_ROUTES.ACCOUNT}/${accountId}/quote`
      );

      if (
        quoteDetails?.status?.success &&
        !quoteDetails.isQuoteNew &&
        !quoteDetails.isCoverageAgreementSigned
      ) {
        return standardRoute(ROUTES.RENTAL_AGREEMENT, accountId);
      }

      return standardRoute(ROUTES.RENTAL_AGREEMENT, accountId);
    },
  },
  /*{
    current: ROUTES.UPDATED_QUOTE,
    nextRoute: async (accountId, flags) => {
      return standardRoute(ROUTES.RENTAL_AGREEMENT, accountId);
    },
    prevRoute: async (accountId) =>
      standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
  },*/
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
    current: ROUTES.RENTAL_AGREEMENT,
    nextRoute: async (accountId, flags) => { return standardRoute(ROUTES.LOSS_RUNS, accountId); },
  },
  {
    prevRoute: async (accountId, flags) => { return standardRoute(ROUTES.RENTAL_AGREEMENT, accountId); },
    current: ROUTES.LOSS_RUNS,
    nextRoute: async (accountId, flags) => { return standardRoute(ROUTES.APPLICATION_PENDING, accountId); },
  },
  /*{
    current: ROUTES.APPLICATION_PENDING,
    nextRoute: async (accountId) =>
      standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId),
    prevRoute: async (accountId, flags) => {
      return standardRoute(ROUTES.LOSS_RUNS, accountId);
    },
  },*/
  {
    prevRoute: async (accountId, flags) => {
      //Skipping going back to the application pending page
      return standardRoute(ROUTES.LOSS_RUNS, accountId);
    },
    current: ROUTES.COVERAGE_AGREEMENT,
    nextRoute: async (accountId) => { return standardRoute(ROUTES.CHECKOUT, accountId); },
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId),
    current: ROUTES.CHECKOUT,
    nextRoute: async (accountId) => standardRoute(ROUTES.COMPLETE, accountId),
  },
];

export const ORP_ROUTES_PROGRESS: FlowRoute[] = [
  {
    prevRoute: async () => ROUTES.INDEX,
    current: ROUTES.INDEX,
    nextRoute: async (accountId) => standardRoute(ROUTES.PERSONAL_INFORMATION, accountId),
  },
  {
    prevRoute: async () => ROUTES.INDEX,
    current: ROUTES.PERSONAL_INFORMATION,
    nextRoute: async (accountId) => standardRoute(ROUTES.BUSINESS_DETAILS, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.PERSONAL_INFORMATION, accountId),
    current: ROUTES.BUSINESS_DETAILS,
    nextRoute: async (accountId) => standardRoute(ROUTES.MY_FLEET, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.BUSINESS_DETAILS, accountId),
    current: ROUTES.MY_FLEET,
    nextRoute: async (accountId) => standardRoute(ROUTES.MY_QUOTE, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.MY_FLEET, accountId),
    current: ROUTES.MY_QUOTE,
    nextRoute: async (accountId) => standardRoute(ROUTES.FORMCRAFT_ORP, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.MY_QUOTE, accountId),
    current: ROUTES.FORMCRAFT_ORP,
    nextRoute: async (accountId) => standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.FORMCRAFT_ORP, accountId),
    current: ROUTES.UPDATE_MY_FLEET,
    nextRoute: async (accountId) => standardRoute(ROUTES.APPLICATION_PENDING, accountId),
  },
  {
    prevRoute: async (accountId, flags) => { return standardRoute(ROUTES.UPDATE_MY_FLEET, accountId); },
    current: ROUTES.APPLICATION_PENDING,
    nextRoute: async (accountId) => standardRoute(ROUTES.SETUP_MY_ACCOUNT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.MY_FLEET, accountId),
    current: ROUTES.SETUP_MY_ACCOUNT,
    nextRoute: async (accountId) => standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.SETUP_MY_ACCOUNT, accountId),
    current: ROUTES.UPDATE_MY_FLEET,
    nextRoute: async (accountId) => {
      const quoteDetails = await api<any, QuoteDetails & IApiBaseResponse>(
        `${API_ROUTES.ACCOUNT}/${accountId}/quote`
      );

      if (
        quoteDetails?.status?.success &&
        !quoteDetails.isQuoteNew &&
        !quoteDetails.isCoverageAgreementSigned
      ) {
        return standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId);
      }

      return standardRoute(ROUTES.UPDATED_QUOTE, accountId);
    },
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
    current: ROUTES.UPDATED_QUOTE,
    nextRoute: async (accountId) => standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.UPDATED_QUOTE, accountId),
    current: ROUTES.COVERAGE_AGREEMENT,
    nextRoute: async (accountId) => standardRoute(ROUTES.CHECKOUT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId),
    current: ROUTES.CHECKOUT,
    nextRoute: async (accountId) => standardRoute(ROUTES.COMPLETE, accountId),
  },
];

export const CONTINUOUS_COVERAGE_GBA_ROUTES_PROGRESS: FlowRoute[] = [
  ...STANDARD_ROUTES_EXCLUDING_QUOTE_SETUP_ACCOUNT,
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.MY_FLEET, accountId),
    current: ROUTES.MY_QUOTE,
    nextRoute: async (accountId) => standardRoute(ROUTES.SETUP_MY_ACCOUNT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.MY_QUOTE, accountId),
    current: ROUTES.SETUP_MY_ACCOUNT,
    nextRoute: async (accountId) => standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.SETUP_MY_ACCOUNT, accountId),
    current: ROUTES.UPDATE_MY_FLEET,
    nextRoute: async (accountId) => {
      const quoteDetails = await api<any, QuoteDetails & IApiBaseResponse>(
        `${API_ROUTES.ACCOUNT}/${accountId}/quote`
      );

      if (
        quoteDetails?.status?.success &&
        !quoteDetails.isQuoteNew &&
        !quoteDetails.isCoverageAgreementSigned
      ) {
        return standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId);
      }

      return standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId);
    },
  },
  /*{
    current: ROUTES.UPDATED_QUOTE,
    nextRoute: async (accountId) =>
      standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId),
    prevRoute: async (accountId) =>
      standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
  },*/
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.UPDATE_MY_FLEET, accountId),
    current: ROUTES.COVERAGE_AGREEMENT,
    nextRoute: async (accountId) => standardRoute(ROUTES.CHECKOUT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId),
    current: ROUTES.CHECKOUT,
    nextRoute: async (accountId) => standardRoute(ROUTES.COMPLETE, accountId),
  },
];

export const LULASAFE_ROUTES_PROGRESS: FlowRoute[] = [
  {
    prevRoute: async () => ROUTES.INDEX,
    current: ROUTES.INDEX,
    nextRoute: async (accountId) => standardRoute(ROUTES.PERSONAL_INFORMATION, accountId),
  },
  {
    prevRoute: async () => ROUTES.INDEX,
    current: ROUTES.PERSONAL_INFORMATION,
    nextRoute: async (accountId) => standardRoute(ROUTES.BUSINESS_DETAILS, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.PERSONAL_INFORMATION, accountId),
    current: ROUTES.BUSINESS_DETAILS,
    nextRoute: async (accountId) => standardRoute(ROUTES.SETUP_MY_ACCOUNT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.BUSINESS_DETAILS, accountId),
    current: ROUTES.SETUP_MY_ACCOUNT,
    nextRoute: async (accountId) => standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.SETUP_MY_ACCOUNT, accountId),
    current: ROUTES.COVERAGE_AGREEMENT,
    nextRoute: async (accountId) => standardRoute(ROUTES.PAYMENT, accountId),
  },
  {
    prevRoute: async (accountId) => standardRoute(ROUTES.COVERAGE_AGREEMENT, accountId),
    current: ROUTES.PAYMENT,
    nextRoute: async (accountId) => standardRoute(ROUTES.COMPLETE, accountId),
  },
];

export enum STAGING_DOMAIN {
  COM = "https://app.getaddify-staging.com",
  IS = "https://app.getaddify-staging.com",
}

export enum PRODUCTION_DOMAIN {
  COM = "https://app.getaddify.com",
  IS = "https://app.getaddify.com",
}

export enum STAGING_HOST {
  COM = "getaddify-staging.com",
  IS = "getaddify-staging.com",
}

export enum PRODUCTION_HOST {
  COM = "getaddify.com",
  IS = "getaddify.com",
}

export const LULA_API_ENDPOINTS = {
  PRODUCTION: {
    UNDERWRITING: `https://api.${PRODUCTION_HOST.IS}/underwriting/v1/underwriting/vin`,
    GET_AROUND_CHECK: `https://api.${PRODUCTION_HOST.IS}/underwriting/v1/compliance/is-getaround`,
  },
  STAGING: {
    UNDERWRITING: `https://api.${STAGING_HOST.IS}/underwriting/v1/underwriting/vin`,
    GET_AROUND_CHECK: `https://api.${STAGING_HOST.IS}/underwriting/v1/compliance/is-getaround`,
  },
} as const;

/** Base URL for Apex REST endpoints that we maintain ourselves. Use `SALESFORCE_CORE_API_BASE_URL` instead when talking to the API directly. */
export const SALESFORCE_APEXREST_BASE_URL = {
  PRODUCTION: `https://lula.my.salesforce.com/services/apexrest`,
  STAGING: `https://lula--uat.sandbox.my.salesforce.com/services/apexrest`,
};

/** Base URL for the Core API of Salesforce. Not to be confused with the Apex REST endpoints. */
export const SALESFORCE_CORE_API_BASE_URL = {
  PRODUCTION: `https://lula.my.salesforce.com/services/data/v62.0`,
  STAGING: `https://lula--uat.sandbox.my.salesforce.com/services/data/v62.0`,
};

export const SALESFORCE_API_AUTH_URL = {
  STAGING: `https://lula--uat.sandbox.my.salesforce.com/services/oauth2/token`,
  PRODUCTION: `https://lula.my.salesforce.com/services/oauth2/token`,
};

/**
 * All the SelfService routes to Salesforce, that are guarded by home-grown APEX classes (sfdc repo).
 * **TODO:** these are being deprecated in favor or direct API routes, to prevent doing the job twice, and to get rid of APEX classes where possible.
 */
export const SALESFORCE_APEX_ROUTES = {
  GET_OPPORTUNITY: "/SelfService/GetFormData/",
  CREATE_LEAD: "/SelfService/CreateLead/",
  CREATE_ACCOUNT: "/SelfService/CreateAccount/",
  ADD_VEHICLE: "/SelfService/AddVehicles/",
  DELETE_VEHICLE: "/SelfService/AddVehicles/",
  UPDATE_OPPORTUNITY: "/SelfService/UpdateOpportunity/",
} as const;

/**
 * Routes directly into the Core API of Salesforce. These replace the APEX class approach above.
 * Advantages: documented API, single responsibility, logged errors, simpler flow.
 */
export const SALESFORCE_CORE_API_ROUTES = {
  UPDATE_STAGE: "/sobjects/Opportunity/",
} as const;

export const SALESFORCE_TRUCKING_API_ROUTES = {
  CREATE_LEAD: "/Trucking/CreateLead/",
  UPDATE_VEHICLE: "/Trucking/Vehicles/",
  DRIVER: "/Trucking/Drivers/",
  ACCOUNT: "/Trucking/CreateAccount/",
  COVERWHALE: "/Trucking/CoverwhaleQuote/",
} as const;

export const ONBOARDING_URLS = {
  production: "https://onboarding.getaddify.com",
  staging: "https://onboarding.getaddify-staging.com",
  development: "http://localhost:3000",
};

export const CUSTOMER_APP_URLS = {
  production: "https://app.getaddify.com",
  staging: "https://app.getaddify-staging.com",
  development: "http://localhost:8081",
};

export const TELEMATICS_BASE_URLS = {
  production: `https://api.getaddify.com/telematics`,
  staging: `https://api.getaddify-staging.com/telematics`,
  development: `http://localhost:30110`,
};

export const POLICY_BASE_URLS = {
  production: `https://api.getaddify.com/policy`,
  staging: `https://api.getaddify-staging.com/policy`,
  development: `https://api.getaddify-staging.com/policy`,
};
export const TELEMATICS_BASE_URL = GetValueForEnvironment(
  TELEMATICS_BASE_URLS.production,
  TELEMATICS_BASE_URLS.staging,
  TELEMATICS_BASE_URLS.development
);

export const POLICY_BASE_URL = GetValueForEnvironment(
  POLICY_BASE_URLS.production,
  POLICY_BASE_URLS.staging,
  POLICY_BASE_URLS.development
);

export const TELEMATICS_INTEGRATION_STATUSES =
  TELEMATICS_BASE_URL + "/tenant-statuses/";

export const TELEMATICS_INTEGRATION_API_SAMSARA =
  TELEMATICS_BASE_URL + "/samsara/integrations";

export const TELEMATICS_INTEGRATION_API_MOTIVE =
  TELEMATICS_BASE_URL + "/motive/integrations";

export const getTelematicsProviderVehiclesUrl = (
  provider: string,
  customerId: string
) => {
  return `${TELEMATICS_BASE_URL}/${provider}/vehicles?tenantId=${customerId}`;
};

export const GetSamsaraOAuthURL = () => {
  const clientID = process.env.NEXT_PUBLIC_SAMSARA_CLIENT_ID;
  let oAuthURL = `https://api.samsara.com/oauth2/authorize?response_type=code`;
  const redirectURL =
    GetBaseUrlForEnv(process.env.NEXT_PUBLIC_ENVIRONMENT) +
    ROUTES.SAMSARA_OAUTH_HANDLER;

  oAuthURL += "&client_id=" + clientID;
  oAuthURL += "&redirect_uri=" + redirectURL;
  oAuthURL += "&state=" + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

  return oAuthURL;
};

export const GetMotiveOAuthURL = () => {
  const clientID = process.env.NEXT_PUBLIC_MOTIVE_CLIENT_ID;
  let oAuthURL =
    "https://api.gomotive.com/oauth/authorize?response_type=code&scope=users.read+vehicles.read+inspection_reports.read+fault_codes.read+driver_performance_events.read+hos_logs.logs+locations.vehicle_locations_list+locations.vehicle_locations_single+companies.read+assets.read+locations.asset_locations+scorecard_summary.read+driving_periods.read";

  const redirectURL =
    GetBaseCustomerAppURL(process.env.NEXT_PUBLIC_ENVIRONMENT) +
    ROUTES.MOTIVE_OAUTH_HANDLER;

  oAuthURL += "&client_id=" + clientID;
  oAuthURL += "&redirect_uri=" + redirectURL;

  return oAuthURL;
};
