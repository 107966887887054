import { ONBOARDING_URLS, CUSTOMER_APP_URLS } from "./constants/routes";

type Method = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

/**
 * Fetch the URL using the method, headers and data as body (unless GET) given.
 * Returns an empty object for 204 or (sometimes) 201 responses.
 * @param url The absolute URL to fetch
 * @param method The method, can be POST, GET, PUT, PATCH, DELETE
 * @param data The body, ignored for GET, will call `JSON.stringify` for serialization
 * @param headers Any headers as KV pairs, including `Bearer` token, to be included. Content type is automatically included as `applcation/json`.
 * @returns An object parsed as `TResponse`. May return an empty object for 204 or 201 HTTP responses.
 */
export const api = async <TData, TResponse>(
  url: RequestInfo,
  method: Method = "GET",
  data: TData = {} as TData,
  headers?: {}
): Promise<TResponse> => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    credentials: "include",
  };

  if (data && method !== "GET") {
    try {
      options.body = JSON.stringify(data);
    } catch (error) {
      console.error(`Fetching URL creating body for ${method} failed. Data may be empty.`, url, error, data);
    }
  }

  let res: Response | null = null;
  try {
    res = await fetch(url, options);
    console.log(`Fetching URL completed, http status = ${res.status}`, url, res.status, res.text);

    const response = await res.text();
    if ((res.status == 200 || res.status == 201 || res.status == 208 /* TODO: must be fixed in API, this is wrong, see business-details submit */) && response) {
      console.log("Fetching url returned 200, 201 or 208 and has a response body");

      try {
        let parsedBody = JSON.parse(response);
        console.log(`Fetching url JSON result parsed as ${typeof(parsedBody)}`);
        return parsedBody;
      } catch (error) {
        console.error("Cannot parse JSON response or response not JSON, returning empty obj instead", url, error, res);
        return {} as TResponse;
      }

    } else if ((res.status == 204 || res.status == 201) && response == null || response.length == 0) {
      console.log("Status is 204 or 201 with no body, returning empty obj");
      return {} as TResponse;

    } else {
      console.log("Other status than 200, 201 or 204, and/or empty body, returning empty obj");
      return {} as TResponse;
    }

  } catch (error) {
    console.error("Fetching URL failed, api error, returning empty obj: ", url, error, res);
  }

  return {} as TResponse;
};

export const GetBaseUrl = (environment?: string) => {
  const env = environment || process.env.ENVIRONMENT;
  if (env == "production") {
    return ONBOARDING_URLS.production;
  } else if (env == "staging") {
    return ONBOARDING_URLS.staging;
  } else {
    return ONBOARDING_URLS.development;
  }
};

export const GetBaseCustomerAppURL = (environment?: string) => {
  const env = environment || process.env.ENVIRONMENT;
  if (env == "production") {
    return CUSTOMER_APP_URLS.production;
  } else if (env == "staging") {
    return CUSTOMER_APP_URLS.staging;
  } else {
    return CUSTOMER_APP_URLS.development;
  }
};
